import React from "react";
import { Rating } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div className="AboutUsDesc">
                  <div>
                    <div className="About-text">
                      <h1>Husariabygg</h1>
                    </div>
                    <div>
                      <p className="Motto">
                        Vi sätter alltid kundens behov i fokus och levererar hög
                        kvalitet i varje projekt. Kontakta oss idag för att
                        diskutera dina byggplaner – vi ser fram emot att
                        förverkliga dina visioner!
                      </p>
                    </div>
                  </div>
                     <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "end", cursor: "pointer" }}>

                      <a href="https://www.brabyggare.se/hantverkare/16958/" target="_blank" style={{display: 'flex', alignItems: "center", userSelect: "none"}}>
                        <Rating
                          value={4.5}
                          readOnly
                          style={{ maxWidth: 150 }}
                        />
                        <span style={{color: '#fff', fontSize: 15, fontWeight: "bold", marginLeft: 5}}>4.85/5</span>
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
