import React, { useEffect, useState } from "react";

export const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const igApi = process.env.REACT_APP_API_URL || 'default_api_url';
        const response = await fetch(`${igApi}/instagram/photos`);
        const data = await response.json();
        setImages(data.data);
      } catch (error) {
        console.error('Error fetching images from Instagram:', error);
      }
    };

    fetchImages();
  }, []);
  console.log("images",images)
  return images?.length ? (
    <div id="portfolio">
      <div className="container">
        <div style={{display: 'flex', justifyContent: "center", flexWrap: "wrap"}}
        >
            {images?.slice(0, 9)?.map((image) => (
              <a href={image.permalink} target="_blank" style={{ color: "#777", margin: "10px 40px" }}>
                  <div key={image.id} 
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <img src={image.media_url} alt={image.caption || 'Instagram image'} className="ig-img" width={300}
                    height={300} />
                    <p className="instagram-text text-center mt-0" style={{width: 300}}>{image.caption}</p>
                  </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  ) : null ;
};