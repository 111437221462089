import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/About_us.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div>
              <h2>Om oss</h2>
            </div>
            <div>
              <p>
              Husaria bygg AB är ett väletablerat företag med lång erfarenhet inom renovering av badrum och kök, samt ombyggnation och nybyggnation av hus. Vårt team består av duktiga och erfarna hantverkare som brinner för sitt arbete. 

Vi förstår att ditt hem är din trygghet, och därför lägger vi stor vikt vid kvalitet och detaljer i varje projekt. Med gedigen kunskap och en passion för byggbranschen, erbjuder vi skräddarsydda lösningar som passar just dina behov och önskemål. Vårt mål är att skapa funktionella och vackra utrymmen som du och din familj kommer att trivas i.

Vi genomför varje år ett flertal framgångsrika projekt, vilket har gett oss ett gott rykte i branschen bland privatpersoner samt företag som vi har långa samarbeten med. Välj oss för din nästa renovering eller byggnation, och få en partner som bryr sig om resultatet lika mycket som du gör. Goda referenser finns att få från våra tidigare projekt. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
