import { FaQuoteLeft } from "react-icons/fa";
import styled from '../Styles/recommendations.module.css'
import { useEffect, useState } from "react";

const initialTestimonials = [
  {
    quote:
      "Mycket bra och förtroendeskapande bemötande inför upphandling av renovering av lägenhet. Bra synpunkter på våra önskemål vid val av matrial och utrustning. Detaljerad offert med utmärkt uppföljning och resultat",
    name: "Claes G",
    title: "",
    company: "",
    image: require("./../asssets/woman.png"),
  },
  {
    quote:
      `Vi har anlitat Husaria för att renovera badrum och kök. Båda renoveringarna utfördes med hög kvalite, på tid och enligt offererad kostnad. Husaria var under hela renoveringen lyhörda för våra krav och önskemål. Husaria hjälpte oss med den sista finishen på många detaljer som vi inte hade tänkt på. Vi är jättenöjda med deras arbete och vi kan varmt rekommendera Husaria för er renovering.`,
    name: "ANDERS H",
    title: "",
    company: "",
    image: require("./../asssets/avatar.webp"),
  },
];


export function Recommendations() {
  const getImageUrl = (defaultImage, url, name) => {
    let imageUrl = null
    switch (defaultImage) {
      case 'none':
        imageUrl = null        
        break;
      case 'woman':
        imageUrl = require("./../asssets/woman.png");
        break;

      case 'man':
        imageUrl = require("./../asssets/avatar.webp")
        break;
    
      default:
        imageUrl = null
        break;
    }
    if(url) {
      return <img src={url} alt={`${name} avatar`} height={40} width={40}/>
    }

    if(imageUrl) {
      return <img src={imageUrl} alt={`${name} avatar`} height={40} width={40}/>
    }

    return null;
    
  }

  const [testimonials, setTestimonials] = useState(initialTestimonials);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/recommendations`, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS"
          }
        })
        const jsonData = await data.json()
        setTestimonials(jsonData);
      } catch (error) {
        console.error('Error fetching Recommendations data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className={styled.section} id="testimonials">
      <div class={styled.main}>
        <h2>Vad våra kunder tycker</h2>
        {/* <h3>Poznaj dlaczego Brand24</h3>
        <h3>to lider monitorigu Internetu w Polsce.</h3> */}
      </div>

      <ul className={styled.list}>
        {testimonials.map(item => 
          <li className={styled.item}>
            <div class={styled.itemContainer}>
              <FaQuoteLeft style={{ marginRight: 15 }} size={25}/>
              <div className={styled.textContainer}>
                <p>
                  {item.quote}
                </p>
                <div className={styled.signature}>
                  <p>{item.name}</p>
                  <p>{item.title}</p>
                  <p>{item.company}</p>
                  {getImageUrl(item.defaultImage, item.image, item.name)}
                </div>
              </div>
            </div>

          </li>
        )}
      </ul>
    </section>
  );
}
