
import styled from '../Styles/Services-new.module.css'
import a1 from '../asssets/1.png'
import a2 from '../asssets/2.png'
import a3 from '../asssets/3.png'
import a4 from '../asssets/4.png'
import a5 from '../asssets/5.png'
import a6 from '../asssets/6.png'
import a7 from '../asssets/7.png'
import a8 from '../asssets/8.png'


export function Services() {
  const servicesList = [
    {
      image: a1,
      title: "Hur lång tid kommer min renovering att ta?",
      text: "Nedan följer olika tidsplaner för de olika renoveringarna.",
      list: ["Badrum 2-6 kvm. 3-4 veckor.","Badrum 7-12 kvm 4-6 veckor.","Wc 2-3 veckor.","Tvättstuga 2-4 veckor."],
      textAfter: "Innan vi startar renoveringen hemma hos dig kommer du få en fast tidsplan för just ditt projekt. I avtalet lägger vi alltid till en vecka ifall någon behöver vara frånvarande från arbetet. Hör av dig till oss för att få veta mer om hur lång tid din renovering tar."
    },
    {
      image: a2,
      title: "När jag bokar renovering med er, kan jag förvänta mig att priset är fast?",
      text: "Ja det kan du göra. Det är viktigt att tänka på att priset är fast enligt offerten du som kund har fått. Står det inte med i offerten ingår det inte i det fasta priset. Läs alltid noga igenom offerten och fråga om du är osäker på om det ingår eller inte så hjälper vi dig."
    },
    {
      image: a3,
      title: "Vilka arbetstider arbetar ni? Kan jag förvänta mig att ni är här varje dag?",
      text: "Hantverkarna arbetar vardagar mellan 07–16. Det kan hända att man arbetar lite extra för att bli klara med dagens moment. Om vi ska arbeta på helgen kommer vi att fråga dig som kund innan. Vi på plats dagligen, ibland kan det ett glapp mellan olika moment och det medför att det kan stå tomt under några timmar. Bli inte orolig! Det är med i tidsplanen från början."
    },
    {
      image: a4,
      title: "Behöver jag förbereda något inför första mötet med er?",
      text: "Det är väldigt bra om du kan skriva ner en önskelista på vad du vill att din renovering ska innefatta. Även om du har några frågor är det bra att skriva ner dem inför mötet."
    },
    {
      image: a5,
      title: "Hur gör man med kakel, klinker & inredning?",
      text: "Alla vi göra på olika sätt. Vi rekommenderar att boka ett möte med en personliga inredare utan kostnad om du känner dig osäker på hur du vill ha det och önskar inspiration. På så sätt får ni hjälp med valet av allt som ni behöver köpa till badrummet. Det gör att renoveringen kommer gå smärtfritt och bli felfri. Du får hjälp med alla val samt en 3D ritning om så önskas. Vissa väljer också att göra allt helt själv vilket inte är något problem för oss."
    },
    {
      image: a6,
      title: "Måste jag välja mindre plattor på golvet?",
      text: "Det går bra att välja större plattor också. Om du väljer att ha en avlångbrunn eller en hörnbrunn kan du gå upp mycket i storlek utan att behöva kapa ner plattorna. Det går även att kuvertskära plattorna på golvet om du väljer att ha en traditionell brunn."
    },
    {
      image: a7,
      title: "Varför ska jag välja att anlita er för min renovering?",
      text: "Det är en bra fråga. Vi har en lång erfarenhet av renoveringar och våtrumsrenoveringar runt om i Stockholm. Vi är anlitade av försäkringsbolag, fastighetsförvaltare och privatpersoner. Vi har träffat på många olika typer av fastigheter. Genom erfarenheten kan vi se vad som är möjligt med just din renovering. Regelverket kan ha ändrats en hel del sedan ditt nuvarande utrymme byggdes. Vi har koll på reglerna! Vår kompetens utmanas hela tiden av marknadens olika besiktningsmän. Kompetensen och erfarenheten är en stor del men inte allt. Mjukavärden som kommunikation, tillgänglighet och hålla vad man lovar är också något som är viktigt. Genom tät kontakt med projektledare och kundansvarig undviker vi dålig kommunikation och missförstånd genom hela projektet."
    },
    {
      image: a8,
      title: "Kommer jag att få något bevis på att mitt badrum är byggt korrekt?",
      text: "Ja, när renoveringen är klar kommer vi att lämna/skicka över intyg från säker vatten samt BKR till dig som kund. Dessa intyg är en värdehandling och är mycket viktiga att ha ex vid en försäljning av bostaden."
    },
  ]

  return (
    <section className={styled.service} id="services">
      <div class={styled.main}>
        <h2>Vi levererar</h2>
      </div>

      <ul className={styled.list}>
        {servicesList.map(({image, text, title, list, textAfter}) => 
          <li className={styled.item}>
            <div className={styled.itemHeader}>
              <img src={image} width={60} height={60}/>
              <h3>{title}</h3>
            </div>
            <p className={styled.textItem}>
              {text}
            </p>
              {!!list?.length && <ul className={styled.additionalList}>
                {list.map((listText) => <li>
                  {listText}
                </li>)}
              </ul>}
              <p className={styled.textItem}>
                {textAfter}
              </p>
          </li>
        )}
      </ul>
    </section>
  );
}
